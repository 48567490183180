var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "설비명",
                              name: "equipmentCd",
                            },
                            on: { dataChange: _vm.equipDataChange },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable,
                              data: _vm.data,
                              etcInput: "locationEtc",
                              label: "장소",
                              name: "locationCd",
                            },
                            model: {
                              value: _vm.data.locationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "locationCd", $$v)
                              },
                              expression: "data.locationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "작업분류",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "작업명",
                              name: "woRequestName",
                            },
                            model: {
                              value: _vm.data.woRequestName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestName", $$v)
                              },
                              expression: "data.woRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              default: "today",
                              label: "요청일",
                              name: "woRequestDt",
                            },
                            model: {
                              value: _vm.data.woRequestDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestDt", $$v)
                              },
                              expression: "data.woRequestDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              editable: _vm.editable,
                              label: "발행부서",
                              required: true,
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              data: _vm.data,
                              type: "user",
                              label: "요청인",
                              name: "userId",
                            },
                            model: {
                              value: _vm.data.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "userId", $$v)
                              },
                              expression: "data.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "긴급작업",
                              name: "emergencyFlag",
                            },
                            model: {
                              value: _vm.data.emergencyFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyFlag", $$v)
                              },
                              expression: "data.emergencyFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable && _vm.data.emergencyFlag == "Y",
                              label: "긴급작업 사유",
                              name: "emergencyReason",
                            },
                            model: {
                              value: _vm.data.emergencyReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyReason", $$v)
                              },
                              expression: "data.emergencyReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 7,
                              label: "정비요청 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "grid",
                              attrs: {
                                title: "정비오더 발행부서",
                                columns: _vm.grid.columns,
                                data: _vm.data.deptList,
                                gridHeight: _vm.grid.height,
                                editable: _vm.editable,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "deptCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [_c("q-btn-group", { attrs: { outline: "" } })],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                staticStyle: { "padding-top": "25px !important" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                    label: "첨부파일",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }